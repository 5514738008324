<template>
  <LicenseTable />
</template>

<script>
export default {
  name: "License",
  components: {
    LicenseTable: () => import("@/components/warehouse/LicenseTable.vue"),
  },
};
</script>

<style scoped></style>
